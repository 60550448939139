/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Project Name
VERSION:	Versoin Number
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/
/*  typography */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bellefair&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Croissant+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

@font-face {
  font-family: 'BwNistaInt-xBd';
  src: url("plugins/fonts/BwNistaInt-xBd.woff") format("woff");
  font-style: normal;
  font-display: swap;
}
*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}
/*main{
  min-height: calc(100vh - 75.78px - 244.78px);
}*/
header{
  overscroll-behavior: contain;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;

}
.bg-home{
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #251e3e ;
  border-radius: 5%;
  box-shadow: -3px 0 14px  rgba(10, 10, 10, 0.8);
}
.contactbg{
  background-image: url("../public/images/background1.jpg");
  background-position: right;
}
hr{
  margin: .5rem 0;
  border: 1px solid #211A1D;
}
.fa-2x {
  font-size: 1.8em;
}
.br-dark{
 border: 1px solid #251e3e !important;
}
.bg-blur{ 
 background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(25px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
ol, ul {
  padding-left: 1.5rem;
}
body {
  line-height: 1.2;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 18px;
  background-image: url("../public/images/background.jpg");
  background-position: center center;
  position: relative;
  padding-bottom: 80px !important;
  overflow-x: hidden;
}

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 180px;
  z-index: 1019;
}
.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  z-index: 1020;
}

.flex-wrap{
  flex-wrap: nowrap !important;
}

.elegantshd {
  color: #131313;
  letter-spacing: .15em;
  text-shadow: 1px -1px 0 #767676, -1px 2px 1px #737272, -2px 4px 1px #767474, -3px 6px 1px #787777, -4px 8px 1px #7b7a7a, -5px 10px 1px #7f7d7d, -6px 12px 1px #828181, -7px 14px 1px #868585, -8px 16px 1px #8b8a89, -9px 18px 1px #8f8e8d, -10px 20px 1px #949392, -11px 22px 1px #999897, -12px 24px 1px #9e9c9c, -13px 26px 1px #a3a1a1, -14px 28px 1px #a8a6a6, -15px 30px 1px #adabab, -16px 32px 1px #b2b1b0, -17px 34px 1px #b7b6b5, -18px 36px 1px #bcbbba, -19px 38px 1px #c1bfbf, -20px 40px 1px #c6c4c4, -21px 42px 1px #cbc9c8, -22px 44px 1px #cfcdcd, -23px 46px 1px #d4d2d1, -24px 48px 1px #d8d6d5, -25px 50px 1px #dbdad9, -26px 52px 1px #FCF7FF, -27px 54px 1px #e2e0df, -28px 56px 1px #e4e3e2;
}
.deepshd {
  color: #e0dfdc;
  letter-spacing: .1em;
  text-shadow: 0 -1px 0 #FCF7FF, 0 1px 0 #2e2e2e, 0 2px 0 #2c2c2c, 0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424, 0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c, 0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414, 0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9);
}
.xbootstrap {
  color: #fcfcfd;
  letter-spacing: .12em;
  /*text-shadow: 0px 5px 5px #251e3e*/
}
.retroshd {
  color: #fcf5f5;
  letter-spacing: .05em;
  text-shadow: 0px 5px 5px #251e3e
}
.retroshd:hover {
    text-decoration: none;
}
.img2{
  position: absolute;
  z-index: 2;    /* Place this image in front of the img1 */
  width: 20%;
  height: 6.5%;
  top:550px;
  left: 400px;
}

p, .paragraph {
  font-weight: 400;
  color: #211A1D ;
  font-size: 20px;
  line-height: 1.8;
}

p-lg, .paragraph-lg {
  font-size: 22px;
}

li{
  font-size: 20px;
  color: #211A1D
}

h1, h2, h3, h4, h5, h6 {
  color: #007a7a;
  line-height: 1.2;
}
h4{
  font-weight: bolder !important;
}
h1, .h1 {
  font-size: 80px;
}

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 65px;
  }
}

h2, .h2 {
  font-size: 35px;
}

h3, .h3 {
  font-size: 40px;
}

h4, .h4 {
  font-size: 22px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 14px;
}

/* Button style */
.btn {
  font-size: 22px;
  box-shadow: 0px 0px 5px 0 rgba(10, 10, 10, 0.8) !important;
  border-radius: 5px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  transition: .2s ease;
  font-weight: lighter;
  text-transform: uppercase;
  color: #eec643;
  border: unset;
}
.btn1 {
  background-color: #eec643 !important;
  border-radius: 5px;
  font-weight: 600;
  position: relative;
  z-index: 1;
  transition: 0.2s ease;
  color: #211A1D !important;
}


.btn-dark{
  color: #211A1D;
}

.btn-scrollTop{
  position: fixed;
  bottom: 40px;
  font-size: 1rem;
  z-index: 1;
  cursor: pointer;
  color: #211A1D;
  right: 2%;
  background: #eec643;
  border-radius: 5px;
  font-weight: normal;

}
.tab{
  width: 15%;
}
.rounded-circle {
  border-radius: 50%!important;
	width: 75px;
	height: 75px;
}
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  box-shadow: none;
}

.btn-sm {
  font-size: 15px;
  padding: 10px 30px;
}

.btn-xs {
  padding: 5px 10px;
  font-size: 15px;
}

.btn-primary {
  background: #fcf7ff  !important;
  box-shadow: 0px 0px 5px 0 rgba(10, 10, 10, 0.8);
  color: #211A1D !important;
  text-transform: uppercase;
  font-weight: normal;
}
.btn-explore{
  background-color: #10889dad !important;
  border-radius: 10px 0px 0px 10px;
  border-right: unset;
}

.btn-primary:active {
  background: #004d7a9a  !important;
  color: #211A1D;
}

.btn-primary:hover {
  background: #211A1D ;
}

.btn-primary.focus, .btn-primary.active {
  background: #251e3e  !important;
  box-shadow: none !important;
  color: #FCF7FF;
}

.btn-transparent {
  background: transparent;
  color: #FCF7FF ;
  font-weight: bold;
}

.btn-transparent:active, .btn-transparent:hover {
  color: #FCF7FF;
}

::-moz-selection {
  background: #542cb7;
  color: #FCF7FF;
}

::selection {
  background: #542cb7;
  color: #FCF7FF;
}

/* preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FCF7FF;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}
.card{
	background-color: unset
}

img {
  vertical-align: middle;
  border: 0;
}
.blog-img{
	height: 180px;
  border-radius: 5px 5px 0 0 !important;
}
header img{
	height: 90px;
}

.radius{
	width: 70%;
	height: 70%; 
	
}


a,
a:hover,
a:focus {
  text-decoration: none;
}

.font-Croissant{
	font-family: "Croissant One";
}
.font-LuckiestGuy{
  font-family: "Luckiest Guy";
}
.font-Great{
	font-family: "Great Vibes";
}
.font-rounded{
  font-family: "Roboto"
}
.font-Bellefair{
	font-family: 'Bellefair';
}

a,
button, 
select {
  cursor: pointer;
  transition: .2s ease;
}

a:focus,
button:focus,
select:focus {
  outline: 0;
}

a.text-dark:hover {
  color: #211A1D !important;
}

a:hover {
  color: #211A1D;
}

.slick-slide {
  outline: 0;
}

.section {
  padding-top: 20px;
  padding-bottom: 30px;
}

.section-title {
  margin-bottom: 80px;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* overlay */
.overlay {
  position: relative;
}

.overlay::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #211A1D;
  opacity: .5;
}p

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.bg-primary {
  background: #1c6e8c !important;
}
.bg-warning{
  background:#eec643  !important;
  letter-spacing: .1em;
}
.border .border-warning{
  border:#eec643  !important;
}
.bg-gray {
  background: #eaeaea !important;
}
.bg-logo{
  background: #10889d56 !important;
}

.text-primary {
  color: #1C6E8C !important;
}

.bg-slide{
  background-color:#251e3e8e;
}
.txt-blue{
  color: #5d8f9f !important;
}
.text-gray {
  color: #eaeaea !important;
}
.text-green {
  color: #c2d8a3c2 !important;
}

.text-dark {
  color: #211A1D  !important;
}
.text-pink{
  color: #f5beb4;
}
.ml-4{
  margin-left: 4%; 
}
.ml-15{
  margin-left:15%;
}
.ml-30{
  margin-left: 30%; 
}
.ml-40{
  margin-left: 30%  !important; 
}
.ml-50{
  margin-left: 50%; 
}
.ml-70{
  margin-left: 70%; 
}
.ml-80{
	margin-left: 80%; 
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 120px !important;
}
.mb-200{
  margin-bottom: 240px !important;
}
.w-15{
  width: 20%;
}
.w-10{
  width: 10%;
}
.w-79{
  width: 79%;
}
.h-98{
 height: 98% !important
}
.logoimg{
  width: 7.5rem;
  height: 7rem;
}
.pb{
  padding-bottom: 3rem !important;
}
.logo{
  width: 12%;
}
.zindex-1 {
  z-index: 1;
}

.rounded-lg {
  border-radius: 15px;
}


.overflow-hidden {
  overflow: hidden;
}

.font-secondary {
  font-family: "Playfair Display", !important;
}

.font-tertiary {
 text-transform: uppercase;
}

.shadow {
  box-shadow: 0px 18px 39.1px 6.9px rgba(224, 241, 255, 0.34) !important;
}

.bg-dark {
  background-color: #211A1D !important;
}
.text-blue{
	color: #0B172C !important
}
.text-light{
	color: #FCF7FF !important;
}

/* icon */
.icon {
  font-size: 45px;
}

.icon-bg {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}

.icon-light {
  color: #2880dd;
}

/* /icon */
/* slick slider */
.slick-dots {
  text-align: center;
  padding-left: 0;
}

.slick-dots li {
  display: inline-block;
  margin: 2px;
}

.slick-dots li.slick-active button {
  background: #FCF7FF;
  width: 25px;
}

.slick-dots li button {
  height: 6px;
  width: 12px;
  background: rgba(255, 255, 255, 0.5);
  color: transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: .2s ease;
  border: 0;
}

/* /slick slider */
/* form */
.form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #c7c7c7;
  height: 60px;
}

.form-control:focus {
  border-color: #41228e;
  outline: 0;
  box-shadow: none !important;
}

textarea.form-control {
  height: 120px;
}

/* /form */
/* card */
.card {
  border: 0;
  height: 100%
}

.card-footer {
  border: 0;
}
.min-vh-90{
  min-height: 90% !important;
}
footer{
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 2px solid #eec643 !important;
  margin-top: 340px !important;
}

.navbar{
  padding: 0;
  justify-content: unset;
}

a.navbar-brand{
  display: contents;
}

/* /card */
.navigation {
  padding: 10px 130px;
  transition: .3s ease;
}

.navigation.nav-bg {
  background-color: #0C2D48;
}
@media (max-width: 480px){
.carousel-caption {
  font-size: large !important;
}
.d-block{
  height: 13rem !important;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  display:none;
}
.sidebar {
  margin-left: -32% !important;

}
.hover-contentbox{
  top: 55% !important;
  left: 0 !important;
}
.hover-content{
  left: 30% !important;
}
}

@media (max-width: 300px){
  h3{
    font-size: 16px !important;
  }
  .ml-30{
    margin-left: 20%;
  }
  .carousel-caption {
    margin-left: -10px !important;
  }
  .fa-2x {
    font-size: 1.4em !important;
}
.rounded-circle {
  border-radius: 50%!important;
  width: 82px;
  height: 82px;
  font-size: 10px;
}
.hover-content {
  left: 22% !important;
}
.p-5 {
  padding: 2rem!important;
}
h2, .h2{
  font-size: 25px;
}
}
@media (max-width: 991px) {
  .navigation.nav-bg {
    padding: 15px 20px;
  }
  .logo{
    width: 25%;
  }
  .navigation {
    padding: 20px;
    background: #eeab59;
  }  
  .h75{
    height: 100% !important;
  }
  .py_5{
    padding-top: unset !important; 
    padding-bottom: unset !important;
  }
  mt_5{
    padding-top: 3rem !important; 
  }
}

.navbar .nav-item .nav-link {
  font-family: "BwNistaInt-xBd";
  text-transform: uppercase;
  padding: 15px;
  padding-top: 4rem;
}
.navbar-nav {
  list-style: none !important;
  text-align: right;
  font-size: 16px !important;
}

.navbar .nav-item.active {
  font-weight: bold;
  
}

.navbar-dark .navbar-nav .nav-link {
color: #FCF7FF!important;
}

.navbar-dark .navbar-nav .active>.nav-link{
	color: #FCF7FF !important;
}
i{
	font-size: 25px;
}
.nav-pills .nav-link.active{
  background: #eec643;
  box-shadow: 0px 0px 5px 0;
  color: #211A1D !important
}
.nav-pills .nav-link:hover{
  color: #211A1D !important;
}
.heading-area {

  position: relative;
  font-family: "croissant one", !important;
}

@media (max-width: 768px) {
  body{
    font-size: 11px;
  }
  .ml-40{
    margin-left: 0 !important; 
  }
	navbar-nav1 {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column-reverse;
    padding-left: 0;
    margin-bottom: 0;
	}
  .Carousel-image{
    max-height: 40rem !important;
   }
.btn-sm{
  padding: 10px 5px;
}

  .heading-area {
    /*padding: 250px 0 150px;*/
    overflow: hidden;
  }

}

@media(max-width: 480px){
  body{
    font-size: 12px !important;
    padding-bottom: 200px !important;
  }
  .mb-200{
    margin-bottom: 330px !important;
  }
  .logo{
    width: 50% !important;
  }
}

.bg{
	background-repeat: no-repeat;
	background-position: right;
	background-size: 200px;
	border-radius: 20%;
	display: flex;
}
.headig-area h1 {
  position: relative;
  z-index: 2;
}

.layer {
  position: absolute;
  z-index: 1;
}

#l1 {
  bottom: 0;
  left: 0;
}

#l2 {
  top: 190px;
  left: -250px;
}

#l3 {
  top: 200px;
  left: 40%;
}

#l4 {
  top: 200px;
  right: 40%;
}

#l5 {
  top: 100px;
  right: -150px;
}

#l6 {
  bottom: -20px;
  left: 10%;
}

#l7 {
  bottom: 100px;
  left: 20%;
}

#l8 {
  bottom: 160px;
  right: 45%;
}

#l9 {
  bottom: 100px;
  right: -10px;
}

.layer-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}
h3, .h3 {
  font-size: 30px;
}

/* skills */
.progress-wrapper {
  height: 130px;
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
}

.wave::before, .wave::after {
  content: "";
  position: absolute;
  width: 800px;
  height: 800px;
  bottom: 0;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 45%;
  -webkit-transform: translateX(-50%) rotate(0);
          transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 6s linear infinite;
          animation: rotate 6s linear infinite;
  z-index: 10;
}

.wave::after {
  border-radius: 47%;
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-transform: translateX(-50%) rotate(0);
          transform: translateX(-50%) rotate(0);
  -webkit-animation: rotate 10s linear -5s infinite;
          animation: rotate 10s linear -5s infinite;
  z-index: 20;
}

@-webkit-keyframes rotate {
  50% {
    -webkit-transform: translateX(-50%) rotate(180deg);
            transform: translateX(-50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
            transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes rotate {
  50% {
    -webkit-transform: translateX(-50%) rotate(180deg);
            transform: translateX(-50%) rotate(180deg);
  }
  100% {
    -webkit-transform: translateX(-50%) rotate(360deg);
            transform: translateX(-50%) rotate(360deg);
  }
}

/* /skills */
/* education */
.edu-bg-image {
  position: absolute;
  left: 0;
  top: -300px;
  z-index: -1;
}

/* /education */
/* service */
.hover-bg-primary {
  transition: .3s ease;
}
.text-white{
  color: #FCF7FF !important;
}
text-warning{
  color: #eec643 !important;
}

.hover-bg-primary:hover {
  background: #41228e;
}

.hover-bg-primary:hover * {
  color: #FCF7FF;
}

.active-bg-primary {
  transition: .3s ease;
  background: #003f5a;
}

.active-bg-primary * {
  color: #FCF7FF;
}

.hover-shadow {
  transition: .2s ease;
}

.hover-shadow:hover {
  box-shadow: 0px 18px 40px 8px rgba(224, 241, 255, 0.54) !important;
}

/* /service */
/* portfolio */
.hover-wrapper {
  overflow: hidden;
}

.hover-wrapper img {
  transition: .3s ease;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.hover-wrapper:hover img {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.hover-wrapper:hover .hover-overlay {
  opacity: 1;
  visibility: visible;
}

.hover-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: .3s ease;
}

.hover-content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 40%;
  right: 0;
  text-align: center;
}
.hover-contentbox{
  position: absolute;
  top: 60%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0%;
  right: 0;
  text-align: center;
}

/* /portfolio */
/* testimonial */
.testimonial-content strong {
  color: #2bfdff;
  font-weight: normal;
}

.testimonial-bg-shapes .container {
  position: relative;
  z-index: 1;
}

.testimonial-bg-shapes .bg-map {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.testimonial-bg-shapes .bg-shape-1 {
  position: absolute;
  left: 100px;
  top: -50px;
}

.testimonial-bg-shapes .bg-shape-2 {
  position: absolute;
  right: 150px;
  top: 50px;
}

.testimonial-bg-shapes .bg-shape-3 {
  position: absolute;
  right: 50px;
  top: 200px;
}

.testimonial-bg-shapes .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 200px;
}

.testimonial-bg-shapes .bg-shape-5 {
  position: absolute;
  right: 200px;
  bottom: -50px;
}
/* start Sidebar*/

.content {
  width: calc(100% - 5px);
  margin-left: auto;
  padding: 15px;
}
.sidebar {
  position: fixed;
  width: 50px;
  height: 6%;
  background-color: #12495a;
  transition: width 0.3s ease;
  padding-top: 15px;
  margin-left: -10%;
  font-size: small;
  color:#211A1D !important;
  border-radius: 10px;
}


.sidebar .sidebar-position {
  height: 50px;
  cursor: pointer;
  display:flex;
  align-items: center;
  padding: 0 12px;
}

.sidebar .sidebar-position:hover {
  background-color: #3f5159;
  border-right: 5px #e84a5f solid;
}

.sidebar svg {
  color: #e84a5f;
  min-width: 25px;
}


.sidebar--open {
  width: 250px;
  transition: width 0.3s ease;
}

.sidebar--open .sidebar-position span {
  display: block;
  width: 100%;
  height: auto;
}

.pro-menu-item > .pro-inner-item {
    position: relative;
    display: flex;
    padding: 20px 45px 0px 0px !important;
    cursor: pointer;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout{
  flex-direction: unset !important;
}
.pro-sidebar{
 padding-left: 18px; 
}
/* end sidebar */ 

/* /testimonial */
/* footer */
.footer-section {
  padding-top: 200px;
}

.section-on-footer {
  margin-bottom: -250px;
}

.shadow-down {
  position: relative;
}

.shadow-down::before {
  position: absolute;
  content: "";
  box-shadow: 0px 0px 80.75px 14.25px rgba(224, 241, 255, 0.34);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
.navbar-toggler-icon{
  border-radius: 5px;
  width: 3rem;
  height: 3rem;
}
.navbar-toggler{
  background-color:#eec643;
  box-shadow: 0px 0px 5px 0 rgba(10, 10, 10, 0.8) !important;
  padding: 5px;
  margin-right: 10px;
}

/* /footer */
.card-lg {
  flex-direction: row-reverse;
  align-items: center;
}

@media (max-width: 991px) {
  .card-lg {
    flex-direction: column;
  }
  navbar-nav {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
	}
  .drag-lg-top {
    margin-top: 0;
  }

  .d-block {
    width: 100%;
    height: 20rem;
    border-radius: 5px;
  }
  .carousel-text{
    padding: .1rem 3rem !important;
  }

}

blockquote {
  display: inline-block;
  padding: 10px 20px;
  background: #eaeaea;
  border-left: 2px solid #41228e;
  font-style: italic;
  font-size: 22px;
}

.content * {
  margin-bottom: 8px;
}

.content strong {
  font-family: "Playfair Display", serif;
  font-size: 22px;
  font-weight: normal;
  color: #211A1D;
  display: inherit;
  line-height: 1.5;
}

.page-title-alt {
  padding: 100px 0 70px;
}

.border-thick {
  border: 2px solid;
}

.drag-lg-top {
  margin-top: -230px;
}

.page-title-alt .container {
  position: relative;
  z-index: 1;
}

.page-title-alt .bg-shape-1 {
  position: absolute;
  left: 0;
  top: 0;
}

.page-title-alt .bg-shape-2 {
  position: absolute;
  left: 70px;
  top: 100px;
}

.page-title-alt .bg-shape-3 {
  position: absolute;
  left: 30%;
  top: 50px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.page-title-alt .bg-shape-4 {
  position: absolute;
  left: 100px;
  bottom: 100px;
}

.page-title-alt .bg-shape-5 {
  position: absolute;
  left: 40%;
  bottom: -25px;
}

.page-title-alt .bg-shape-6 {
  position: absolute;
  bottom: 100px;
  right: -100px;
}

.page-title {
  padding: 100px 0 70px;
}

.page-title .container {
  position: relative;
  z-index: 1;
}

.page-title .bg-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.page-title .bg-shape-2 {
  position: absolute;
  left: -20px;
  top: -10px;
}

.page-title .bg-shape-3 {
  position: absolute;
  left: 10%;
  top: 100px;
}

.page-title .bg-shape-4 {
  position: absolute;
  left: 50%;
  top: -20px;
}

.page-title .bg-shape-5 {
  position: absolute;
  left: 90px;
  bottom: -50px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.page-title .bg-shape-6 {
  position: absolute;
  right: 20%;
  bottom: -20px;
}

.page-title .bg-shape-7 {
  position: absolute;
  right: -220px;
  bottom: -100px;
}

.filter-controls li {
  cursor: pointer;
}

.filter-controls li.active {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

.carousel-caption {
  position: absolute;
  top: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  font-family:serif;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  font-size: xx-large;
  font-weight:bold;
  border-radius: 8px;
}
#carouselExampleSlidesOnly .carousel-control-next-icon, #carouselExampleSlidesOnly .carousel-control-prev-icon {
display: none !important
}
#carouselExampleControls .carousel-indicators
{
  display: none;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  border-radius: 50px;
  background-color: #ccc;
  color: #211A1D;
  padding: 1rem;
  border: 1px solid #211A1D;
  box-shadow: 0px 5px 5px #211A1D;
}

.d-block{
  width: 100%;
  height: 90vh;
  border-radius: 5px;

}
.text-bg{
   background-color: #FCF7FF;
}
.carousel-indicators [data-bs-target]  {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color:#121212;
}
.slide1 {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-animation: slideIn 2s forwards;
  -moz-animation: slideIn 2s forwards;
  animation: slideIn 2s forwards;
}

.slideimg{
  -webkit-animation:cubic-bezier(1, 0, 0, 1) forwards;
  -moz-animation: cubic-bezier(1, 0, 0, 1) forwards;
  animation: cubic-bezier(1, 0, 0, 1) forwards;
}
.carousel-text{
  padding: 7rem 3rem;
  font-size: 38px;
}
.Carousel-image{
 max-height: 23rem;
 background-repeat: no-repeat;
 background-position: center;
}

.h75{
  height: 75% ;
}
.py_5{
  padding-top: 3rem; 
  padding-bottom: 3rem;
}
mt_5{
  padding-top: unset; 
}
.mtop-5{
  margin-top: 5rem;
}
.carousel-control {
  position: absolute;
  top: 50%; /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
  }

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(900px);
  }
  100% {
    transform: translateX(0);
  }
}
:root {
  --background-body: #292929;
  --background-content: #1D1D1D;
  --text-color: #E5E5E5;
  --primary-color: #4DFF7C;
  --second-color: #89DDFF;
}
main.main {
  width: 50%;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 4.5rem;
  background-color: var(--background-content);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 3px;
}

blockquote {
  background: rgba(77, 255, 124, 0.2);
  border-left: 10px solid var(--primary-color);
  margin: 1.5em 10px;
  padding: 1em 10px .1em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

code {
  color: var(--second-color);
  background-color: rgba(137, 221, 255, 0.3);
}

.dark-mode-icon {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  main.main {
    width: 70%;
  }
}

@media screen and (max-width: 920px) {
  main.main {
    width: 80%;
  }
  
}

@media screen and (max-width: 520px) {
  main.main {
    width: 90%;
  }
  .carousel-text{
    padding: 3rem 2rem !important;
    font-size: 28px !important;
  }
  .mtop-5{
    margin-top: 1rem;
  }
 
}
table {
  caption-side: bottom;
   border-collapse: unset !important;
}

/* Tab Container */
.Tabs {
  width: 100%;
  background-image: url(http://localhost:3000/static/media/background1.50716d1….jpg);
  background-position: right;
  height: auto;
  min-height: 400px;
  padding: 1rem;
  font-weight: normal;
  color: #1c1c1c;
  border-radius: .5rem;
  z-index: 1;
}
 
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
 
.react-tabs__tab-list {
  width: 60%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #39A2DB;
  border-radius: .5rem;
  padding-left: 0px;
}
 
.react-tabs__tab {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
}
 
.react-tabs__tab--selected {
  background: #d25415d3;
}
 
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
 
.react-tabs__tab:hover {
  background: rgba(50, 224, 196, 0.15);
}
 
.react-tabs__tab:first-child {
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
}
 
.react-tabs__tab:last-child {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;
}
 
.react-tabs__tab-panel {
  display: none;
}
 
.react-tabs__tab-panel--selected {
  display: block;
  font-size: 2rem;
  text-align: center;
}


/* Tab Navigation */
ul.nav {
  width: 15% ;
  margin: 0 auto ;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  border: 1px solid #2E151B;
  border-radius: .5rem;
  padding-left: 0px;
  @media (max-width: 991px) {
    width: 30%;
  }
}

 
ul.nav li {
  width: 50%;
  padding: .30rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  box-shadow: -5px 0 14px  rgba(10, 10, 10, 0.301);
}
 
ul.nav li:first-child {
  border-bottom-left-radius: .5rem;
  border-top-left-radius: .5rem;
}
 
ul.nav li:last-child {
  border-bottom-right-radius: .5rem;
  border-top-right-radius: .5rem;
}
 
ul.nav li:hover {
  background: rgba(50, 224, 196, 0.15);
}
 
ul.nav li.active {
  background: #fff;
  color:#211A1D
}
 
/* slick slider */
.slick-dots {
  text-align: center;
  padding-left: 0;
}
.slick-dots li {
  display: inline-block;
  margin: 2px;
}
.slick-dots li.slick-active button {
  background: #eed78c;
  width: 25px;
}
.slick-dots li button {
  height: 6px;
  width: 12px;
  background: rgba(255, 255, 255, 0.5);
  color: transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.2s ease;
  border: 0;
}

/* /slick slider */

.images {
  /* height: 400px; */
  font-size: 25px;
  margin: 5px !important;
  display: inline-block;
}
 .arrow{
   box-shadow: 0px 0px 5px 0 #eec643 !important;
   border-radius: 50px;
   padding: 2px;
 }
 .rimg {
  height: 250px;
}
.row {
  --bs-gutter-x: unset;
}

* {
  scrollbar-color: #1C6E8C#fcf7ff;
}
.slick-next {
  right: 38px;
  z-index: 1;
}
.slick-prev {
  left: 22px;
  z-index: 1;
}
.slick-next:before, .slick-prev:before {
  font-size: 30px;
  line-height: 1;
  color: #eec643;
}
.slick-dots li button:before {
  line-height: 10px;
}