body{
    padding-bottom: 100px !important;
  }

  :root {
    --angle: 45deg;
    --opacity: 0.5;
  }
  


*,
*::before,
*::after {
  box-sizing: border-box;
}

.rainbow {

  --border-size: 0.3rem;
  letter-spacing: .1em;
  /* Paint an image in the border */
  border-image: conic-gradient(
      from var(--angle),
      #eee973 0deg 90deg,
      #000 90deg 180deg,
      #eee973 180deg 270deg,
      #000 270deg 360deg
    )
    1 stretch;
  background: rgb(255 255 255 / var(--opacity));
}

/* Animate when Houdini is available */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
     
    }
  }

  .rainbow {
    animation: rotate 15s alternate infinite, opacityChange 3s infinite alternate;

  }
}
/* button */

.button{ 
  position:relative;
  outline:none;
  transition: .13s ease-in-out;
  cursor:pointer;
  border-radius: 50px 30px;
  box-shadow: 0px 0px 5px 0 rgba(10, 10, 10, 0.8);
  animation-name: button;
  animation-duration: 0.75s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}
.btnhome{
  cursor:pointer;
  box-shadow: 10px 0px 15px #000;
  animation-name: button;
  color: #000;
  background: #446c8da8;
}

.button:hover {
  color: #fff !important;
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}


.btn-text {
  color: #333;
  font-size: 1rem;
  line-height: 1.2rem;
  opacity: 1;
  animation-name: button-text;
  animation-duration: 0.75s;
  animation-delay: 1.3s;
  animation-fill-mode: backwards;
}

button:before,
button:after,
button span:before,
button span:after{
  content: '';

  top: 0;
  left: 0;
  border-radius: 25px;
}

button:before {
/*   box-shadow: -10px -8px 20px #fff; */
  animation-name: button-before;
  animation-duration: 0.75s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

button:after {
/*   box-shadow: 10px 8px 20px #9496B0; */
  animation-name: button-after;
  animation-duration: 0.75s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.btn-inner {
  max-width: 250px;
  position: relative;
  background: #F9DC5C;
/*   background: linear-gradient(165deg, rgba(255,255,255,1) 0%, rgba(210,213,216,1) 50%, rgba(210,213,216,1) 95%, rgba(148,150,176,1) 100%); */
  display: block;
  border-radius: 25px;
  position: relative;
  animation-name: button-inner;
  animation-duration: 0.75s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.button.active .btn-text {
  font-size: 0.95rem;
}

.button.active .btn-inner:before {
   box-shadow: 4px 4px 10px inset #9496B0;
  -webkit-box-shadow: 4px 4px 10px inset #9496B0;
}

button.active .btn-inner:after {
  box-shadow: -4px -4px 10px inset #fff;
  -webkit-box-shadow: -4px -4px 10px inset #fff;
}

@keyframes button-before {
  from {box-shadow: 0 0 0 #fff;}
  to {box-shadow: -10px -8px 20px #fff;}
}

@keyframes button-after {
  from {box-shadow: 0 0 0 #9496B0;}
  to {box-shadow: 10px 8px 20px #9496B0;}
}

@keyframes button-inner {
  from {background: #F9DC5C;}
  to {background: linear-gradient(165deg, #F9DC5C 0%, #F9DC5C 50%, rgba(210,213,216,1) 95%, rgba(148,150,176,1) 100%);}
}

@keyframes button {
  from {background: #FCF7FF;}
  to {background: linear-gradient(165deg, #FCF7FF 10%, #FCF7FF 100%);}
}

@keyframes button-text {
  from {opacity: 0;}
  to {opacity: 1;}
}

